<template>
  <div class="layout-header-section d-lg-flex d-block justify-content-between">
    <div class="header-label col-lg-4 col-12">Campaign List</div>
    <div class="
        col-lg-8 col-12
        text-end
        d-flex
        justify-content-end
        custom-flex-cloumn-mob
      ">
      <button type="button" @click="newcampignModalOpen()" class="header-btn-outer btn btn-primary">
        New Campaign
      </button>
    </div>
  </div>
  <div class="custom-ultima-datatable" style="height: calc(100vh - 141px)">
    <DataTable :value="campaignList" :scrollable="true" scrollHeight="flex" :paginator="true" :rows="30" :lazy="true"
      :rowHover="true" :totalRecords="totalRecords" @page="changePage($event)"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      responsiveLayout="scroll" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" :loading="loading"
      dataKey="co1">
      <template v-if="!loading" #empty>No records found.</template>
      <template #loading><i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i></template>
      <Column field="name" header="Campaign Name" headerStyle="width: 40%" bodyStyle="width: 40%">
        <template #body="{ data }">
          <div>
            <div class="text-capitalize">
              {{ data.co2 ? data.co2 : "N/A" }}
            </div>
          </div>
        </template>
      </Column>
      <Column field="type" header="Campaign Type" headerStyle="width: 13%" bodyStyle="width: 13%">
        <template #body="{ data }">
          <div>
            <span v-if="data.co3 == 1">Inteligent</span>
            <span v-if="data.co3 == 2">WhatsApp</span>
            <span v-if="data.co3 == 3">RCS</span>
            <span v-if="data.co3 == 4">iMessage</span>
            <span v-if="data.co3 == 5">SMS</span>
            <span v-if="data.co3 == 6">Email</span>
            <span v-if="data.co3 == 7">Calls</span>
            <span v-if="data.co3 == ''">N/A</span>
          </div>
        </template>
      </Column>
      <Column field="count" header="No. of Count" class="justify-content-center" headerStyle="width: 12%"
        bodyStyle="width: 12%">
        <template #body="{ data }">
          <div>
            {{ data.co7 ? data.co7 : "N/A" }}
          </div>
        </template>
      </Column>
      <Column field="date" header="Scheduled Data & Time" headerStyle="width: 20%" bodyStyle="width: 20%">
        <template #body="{ data }">
          <div>{{ format_timestamp(data.co5) }}</div>
          <!-- <div>
            <div class="text-capitalize">
              {{ data.co5 ? data.co5 : "N/A" }}
            </div>
          </div> -->
        </template>
      </Column>
      <Column field="category" header="Action" headerStyle="width: 15%" bodyStyle="width: 15%">
        <template #body="{ data }">
          <div>
            <button type="button" class="btn custom-view-detail-btn mb-2 w-100" @click="campaignLogsRedirectPage(data)">
              View Logs
            </button>
            <button disabled type="button" class="btn custom-view-detail-btn w-100"
              @click="campaignReportRedirectPage(data)">
              View Reports
            </button>
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
  <!-- Add New Campign modal start here -->
  <div class="modal-mask" v-if="newcampaignmodalstatus">
    <div class="modal-dialog modal-xl modal-dialog-scrollable modal-dialog-centered
            custom-modal-outer">
      <div class="modal-content">
        <div class="modal-header whatsapp-header">
          <h5 class="modal-title" id="staticBackdropLabel">New Campaign</h5>
          <button type="button" class="btn-close" @click="newcampignModalClose()"></button>
        </div>
        <div class="modal-body">
          <div class="modal-body-fixed-height">
            <div v-if="!showmodalloader">
              <div class="row" v-if="campaignfilterstatus">
                <div class="col-12">
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-12">
                      <div class="custom-form-group">
                        <div class="form-check form-check-inline custom-inline-checkbox">
                          <input class="form-check-input" @change="clearfilterinput(campaignallvoters)"
                            v-model="campaignallvoters" type="checkbox" id="allvoters" value="1">
                          <label class="form-check-label" for="allvoters">Select All Guests</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row" v-show="!hideotherfilter">
                    <div class="col-lg-12 col-md-12 col-12">
                      <div class="custom-form-group">
                        <label class="from-label-value text-primary">-- OR --</label>
                      </div>
                    </div>
                  </div>
                  <div class="row" v-show="!hideotherfilter">
                    <div class="col-12" v-if="campaigncountstatus">
                      <div class="custom-form-group">
                        <div class="custom-img-error">No records found.</div>
                      </div>
                    </div>
                  </div>
                  <div class="row" v-show="!hideotherfilter">
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="custom-form-group">
                        <label class="form-label">Guest Name</label>
                        <input type="text" v-model="ak5" class="form-control text-capitalize"
                          id="formfirstnameInput" placeholder="Enter Name" autocomplete="off"
                          @keypress="isLetterWithSpace($event)" />
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                        <div class="custom-form-group">
                            <label class="form-label">Mobile No.</label>
                            <div class="input-group custom-input-group">
                                <div class="col-lg-4 col-md-4 col-12">
                                    <Multiselect v-model="countrycode"
                                        :options="countryCodeList"
                                        :searchable="true" label="label"
                                        placeholder="Select" disabled
                                        class="multiselect-custom custom-title-multiselect"
                                        :canClear="false" :closeOnSelect="true"
                                        :object="true" noOptionsText="No Result"
                                        :canDeselect="false" />
                                </div>
                                <div class="col-lg-8 col-md-8 col-12">
                                    <input type="text" v-model="ak24"
                                        class="form-control"
                                        placeholder="Enter Mobile No."
                                        maxlength="10" id="formMobileNoInput"
                                        @keypress="onlyNumberMobile"
                                        autocomplete="off" />
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                  <div class="row" v-show="!hideotherfilter">
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="custom-form-group">
                        <label class="form-label">Blood Group</label>
                        <Multiselect v-model="ak20" :options="bloodgroupList" mode="tags" :createTag="false"
                          :searchable="true" label="label" placeholder="Select" class="multiselect-custom text-capitalize"
                          :canClear="true" :closeOnSelect="true" :object="false" />
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="custom-form-group">
                        <label class="form-label">Gender</label>
                        <Multiselect v-model="ak22" :options="genderList" mode="tags" :createTag="false" label="label"
                          class="multiselect-custom text-capitalize" placeholder="Select" :closeOnSelect="true"
                          :searchable="false" :object="false" />
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="custom-form-group">
                        <label class="form-label">Pincode</label>
                        <input type="text" v-model="pincodevalue" id="addpincodeInput" class="form-control"
                          placeholder="Enter Pincode" minlength="6" maxlength="6" @keypress="onlypincodeAddFormat"
                          autocomplete="off" />
                        <span class="custom-error" v-if="pincodeerr">
                          {{ pincodeerr }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="row" v-show="!hideotherfilter">
                    <div class="col-lg-3 col-md-3 col-12">
                      <div class="custom-form-group">
                        <label class="form-label">Age</label>
                        <input type="text" v-model="agerange" class="form-control" placeholder="Enter Age" minlength="1"
                          maxlength="2" @keypress="onlysingleagechange" autocomplete="off" />
                        <span class="custom-error" v-if="agerangeeerr">
                          {{ agerangeeerr }}
                        </span>
                      </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-12">
                      <div class="customer-label-group text-center" style="margin-top: 35px;">
                        <label class="from-label-value">-- OR --</label>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-12">
                      <div class="row custom-form-group">
                        <div class="col-lg-5 col-md-5 col-12">
                          <div class="custom-form-group mb-0">
                            <label class="form-label">Age Range</label>
                            <input type="text" v-model="agerangefrom" class="form-control" placeholder="From"
                              minlength="1" maxlength="2" @keypress="agerangechange" autocomplete="off" />
                          </div>
                        </div>
                        <div class="col-lg-2 col-md-2 col-12">
                          <div class="custom-form-group text-center mb-0" style="margin-top: 35px;">-</div>
                        </div>
                        <div class="col-lg-5 col-md-5 col-12">
                          <div class="custom-form-group mb-0" style="margin-top: 5px;">
                            <label class="form-label"></label>
                            <input type="text" v-model="agerangeto" :disabled="agerangefrom == ''" class="form-control"
                              placeholder="To" minlength="1" maxlength="2" @keypress="agerangechange"
                              autocomplete="off" />
                          </div>
                        </div>
                        <span class="custom-error" v-if="agerangefromeerr">
                          {{ agerangefromeerr }}
                        </span>
                        <span class="custom-error" v-if="agerangetoeerr">
                          {{ agerangetoeerr }}
                        </span>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="custom-form-group">
                        <label class="form-label">Area</label>
                        <Multiselect v-model="ak44" :options="filterareaList" mode="tags" :createTag="false"
                          :searchable="true" label="label" placeholder="Select" class="multiselect-custom text-capitalize"
                          :canClear="true" :closeOnSelect="true" :object="false" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" v-if="createtempaltestatus">
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="voter-already-user-status text-center">Total {{ this.filterListCount }} result found.
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="custom-form-group">
                      <label class="form-label">Campaign Name <span class="text-danger">*</span></label>
                      <input type="text" v-model="campaignname" class="form-control" placeholder="Enter Campaign Name"
                        maxlength="250" autocomplete="off" />
                      <div class="custom-error" v-if="v$.campaignname.$error">
                        {{ v$.campaignname.$errors[0].$message }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4 col-md-4 col-12">
                    <div class="custom-form-group">
                      <label class="form-label">Select Campaign Type <span class="text-danger">*</span></label>
                      <Multiselect v-model="campaigntype" @change="getcampaigntemplates($event)"
                        :options="campaigntypeList" :searchable="false" label="label" placeholder="Select Campaign Type"
                        class="multiselect-custom text-capitalize" :canClear="true" :closeOnSelect="true" />
                      <div class="custom-error" v-if="v$.campaigntype.$error">
                        {{ v$.campaigntype.$errors[0].$message }}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-8 col-md-8 col-12">
                    <div class="custom-form-group">
                      <label class="form-label">Select Template <span class="text-danger">*</span></label>
                      <Multiselect v-show="!templateloaderflag" :disabled="this.campaigntype == ''" v-model="templatename"
                        :options="getcampaigntemplateList" :searchable="false" label="label" placeholder="Select Template"
                        class="multiselect-custom" :canClear="true" :closeOnSelect="true" />
                      <Multiselect v-show="templateloaderflag" loading :searchable="false" label="label"
                        placeholder="Select Template" class="multiselect-custom" noOptionsText="No Result" />
                      <div class="custom-error" v-if="v$.templatename.$error">
                        {{ v$.templatename.$errors[0].$message }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4 col-md-4 col-12">
                    <div class="custom-form-group">
                      <label for="formBirthDateInput" class="form-label">Schedule Date & Time <span
                          class="text-danger">*</span></label>
                      <div class="input-group">
                        <div class="col-lg-6 col-md-6 col-6">
                          <Calendar v-model="scheduledate" :showIcon="true" class="custom-ultima-calendar"
                            placeholder="DD/MM/YYYY" dateFormat="dd/mm/yy" :minDate="todaysDate" :manualInput="false"
                            :monthNavigator="true" :yearRange="'2020:' + new Date().getFullYear()" appendTo="body"
                            :yearNavigator="true" @date-select="handleInterviewDate()" />

                        </div>
                        <div class="col-lg-6 col-md-6 col-6">
                          <Calendar id="time12" placeholder="HH:MM" v-model="scheduletime" :timeOnly="true"
                            :manualInput="false" hourFormat="12" class="custom-ultima-calendar custom-time-outer"
                            appendTo="body" :disabled="!scheduledate" :stepMinute="15" />

                        </div>
                      </div>
                      <div class="custom-error" v-if="v$.scheduledate.$error">
                        {{ v$.scheduledate.$errors[0].$message }}
                      </div>
                      <div class="custom-error" v-if="scheduledate != null && v$.scheduletime.$error">
                        {{ v$.scheduletime.$errors[0].$message }}
                      </div>
                      <div class="custom-error" v-if="errormsg">{{ errormsg }}</div>
                    </div>
                  </div>
                </div>
                <div class="row" v-if="balancealertstatus">
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="balance-alert-status-outer text-center">
                      <div v-if="this.balancealert1">{{ this.balancealert1 }}</div>
                      <div v-if="this.balancealert2" class="mt-2">{{ this.balancealert2 }}</div>
                      <div v-if="this.balancealert3" class="mt-2">{{ this.balancealert3 }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="showmodalloader">
              <div class="custom-modal-spinner-loader">
                <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="col-12 m-0" v-if="campaignfilterstatus">
            <div class="d-flex justify-content-between">
              <button class="btn filter-apply-btn"
              :disabled="(ak18 == '' || ak18 == null) && (ak62 == '' || ak62 == null) && (ak60 == '' || ak60 == null) && (ak20 == '' || ak20 == null) && (this.ak22 == '' || this.ak22 == null) && (ak44 == null || ak44 == '') && ak5 == '' && ak24 == '' && pincodevalue == '' && agerange == '' && agerangefrom == '' && agerangeto == '' && campaignallvoters == ''"
                @click="
                  btnFilterSearch(
                    ak29,
                    ak5,
                    ak24,
                    ak62,
                    ak60,
                    ak44,
                    ak20,
                    ak22,
                    pincodevalue,
                    agerange,
                    agerangefrom,
                    agerangeto,
                    ak18,
                  )
                  ">
                Apply Filter
              </button>
              <button class="btn btn-link filter-reset-btn" @click="resetVoterFilter">
                Reset All
              </button>
            </div>
          </div>
          <div class="col-12 m-0 text-end" v-if="createtempaltestatus">
            <button type="button" class="btn filter-apply-btn" @click="CreateNewCampaignBtnbtn()" :disabled="createloader"
              :style="{ width: '70px' }">
              <span v-if="!createloader">Submit</span>
              <div class="spinner-border text-light custom-spinner-loader-btn" role="status" v-if="createloader">
                <span class="visually-hidden">Loading...</span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Add New Campign modal end here -->
</template>
<script>
import { required, helpers } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import ApiService from "../../../service/ApiService";
import router from "@/router";
import moment from 'moment';
export default {
  data() {
    return {
      v$: useValidate(),
      campaignList: [],
      totalRecords: 0,
      loading: false,
      newcampaignmodalstatus: false,
      createtempaltestatus: false,
      campaignfilterstatus: false,

      ak5: '',
      ak24: '',
      ak62: [],
      ak60: [],
      ak20: [],
      ak18: null,
      ak22: [],
      agerange: '',
      agerangefrom: '',
      agerangeto: '',
      pincodevalue: '',
      pincodeerr: '',
      ak44: [],


      filterareaList: [],
      bloodgroupList: [],
      genderList: [
        { label: "Male", value: 1 },
        { label: "Female", value: 2 },
        { label: "Others", value: 3 },
      ],


      agerangeeerr: '',
      agerangefromeerr: '',
      agerangetoeerr: '',
      errStatus: false,
      errmsg: "",

      showmodalloader: false,
      filterList: [],
      campaigncountstatus: false,
      createloader: false,
      filterListCount: '',

      campaignname: '',
      campaigntype: '',
      templatename: '',
      scheduledate: null,
      scheduletime: null,
      campaigntypeList: [
        { label: "WhatsApp", value: 2 },
        { label: "Calls", value: 7 },
        { label: "Push Notification", value: 8 },
      ],
      getcampaigntemplateList: [],
      templateloaderflag: false,
      todaysDate: new Date(moment().add(4, 'hours')._d),
      balancealert1: '',
      balancealert2: '',
      balancealert3: '',
      campaignallvoters: '',
      hideotherfilter: false,
      errormsg: '',
      balancealertstatus: false,
      countrycode: { value: 101, label: "+91" },
      countryCodeList: [],
      // maxDateBirth: moment().subtract(1, "years")._d,
      // minDateBirth: moment().subtract(120, "years")._d,
    };
  },
  watch: {
    pincodevalue(pincodevalue) {
      this.errmsg = "";
      this.validatePinCodeNumber(pincodevalue);
      if (pincodevalue.length == 0) {
        this.pincodeerr = "";
        this.errStatus = false;
      }
    },
    agerange(agerange) {
      this.errmsg = "";
      this.validateAgeRangeNumber(agerange);
      if (agerange.length == 0) {
        this.agerangeeerr = "";
        this.errStatus = false;
      }
    },
    agerangefrom(agerangefrom) {
      this.errmsg = "";
      this.validateAgeRangeFromNumber(agerangefrom);
      if (agerangefrom.length == 0) {
        this.agerangefromeerr = "";
        this.errStatus = false;
      }
    },
    agerangeto(agerangeto) {
      this.errmsg = "";
      this.validateAgeRangeToNumber(agerangeto);
      if (agerangeto.length == 0) {
        this.agerangetoeerr = "";
        this.errStatus = false;
      }
    },
  },
  ApiService: null,
  created() {
    this.ApiService = new ApiService();
  },
  validations() {
    return {
      campaignname: {
        required: helpers.withMessage("Please enter campaign name", required),
      },
      campaigntype: {
        required: helpers.withMessage("Please select campaign type", required),
      },
      templatename: {
        required: helpers.withMessage("Please select template name", required),
      },
      scheduledate: {
        required: helpers.withMessage("Please select schedule date", required),
      },
      scheduletime: {
        required: helpers.withMessage("Please select schedule time", required),
      },
    };
  },
  mounted() {
    this.loading = true;
    this.getcampaign();
    // this.getbloodgroups();
    // this.getareas();
  },
  methods: {

    newcampignModalOpen() {
      this.newcampaignmodalstatus = true;
      this.campaignfilterstatus = true;
      this.createtempaltestatus = false;
      this.ak29 = 1;
      this.ak5 = '',
      this.ak24 = '',
      this.ak18 = null;
      this.ak62 = null;
      this.ak60 = null;
      this.ak20 = null;
      this.ak22 = null;
      this.agerange = "";
      this.agerangefrom = '';
      this.agerangeto = '';
      this.pincodevalue = "";
      this.ak44 = null;
      this.agerangeeerr = '';
      this.pincodeerr = '';
      this.ak85 = null;
      this.ak121 = null;
      this.campaignname = '';
      this.campaigntype = '';
      this.templatename = '';
      this.scheduledate = null;
      this.scheduletime = null;
      this.createloader = false;
      this.balancealert1 = '';
      this.balancealert2 = '';
      this.balancealert3 = '';
      this.campaignallvoters = '';
      this.hideotherfilter = false;
      this.balancealertstatus = false;
    },
    newcampignModalClose() {
      this.newcampaignmodalstatus = false;
      this.campaignfilterstatus = false;
      this.createtempaltestatus = false;
      setTimeout(() => {
        this.v$.$reset();
      }, 0);
    },
    getcampaign(e) {
      this.ApiService.getcampaign(e).then((data) => {
        if (data.success === true) {
          this.campaignList = data.data;
          this.totalRecords = data.count;
          this.loading = false;
        } else {
          this.loading = false;
          this.campaignList = null;
          this.totalRecords = 0;
        }
      });
    },
    changePage(event) {
      this.page_no = event.page;
      this.getcampaign({ page_no: this.page_no });
    },
    btnFilterSearch(
      ak29,
      ak5,
      ak24,
      ak62,
      ak60,
      ak44,
      ak20,
      ak22,
      pincodevalue,
      agerange,
      agerangefrom,
      agerangeto,
      ak18
    ) {
      if (!this.agerangeeerr && !this.pincodeerr && !this.agerangefromeerr && !this.agerangetoeerr) {
        this.ak29 = ak29;
        this.ak5 = ak5;
        this.ak24 = ak24;
        this.ak62 = ak62;
        this.ak60 = ak60;
        this.ak20 = ak20;
        if(ak18 != null) {
          this.ak18 = moment(ak18).format("YYYY-MM-DD");
        }
        this.ak22 = ak22;
        this.agerange = agerange;
        this.agerangefrom = agerangefrom;
        this.agerangeto = agerangeto;
        this.pincodevalue = pincodevalue;
        this.ak44 = ak44;
        this.getCampaignVoterList({
          ak29: this.ak29,
          ak5: this.ak5,
          ak24: this.ak24,
          ak62: this.ak62,
          ak60: this.ak60,
          ak20: this.ak20,
          ak18: this.ak18,
          ak22: this.ak22,
          ak19: this.agerange,
          ak19from: this.agerangefrom,
          ak19to: this.agerangeto,
          ak49: this.pincodevalue,
          ak44: this.ak44
        });
      } else {
        this.showmodalloader = false;
        this.campaignfilterstatus = true;
        this.createtempaltestatus = false;
        this.campaigncountstatus = false;
      }
    },
    resetVoterFilter() {
      this.ak29 = '';
      this.ak5 = '';
      this.ak24 = '';
      this.ak62 = null;
      this.ak60 = null;
      this.ak20 = null;
      this.ak18 = null;
      this.ak22 = null;
      this.agerange = "";
      this.agerangefrom = '';
      this.agerangeto = '';
      this.pincodevalue = "";
      this.ak44 = null;
      this.agerangeeerr = '';
      this.pincodeerr = '';
      this.campaigncountstatus = false;
    },
    getCampaignVoterList(e) {
      this.showmodalloader = true;
      this.ApiService.getCampaignVoterList(e).then((data) => {
        if (data.success == true) {
          this.filterList = data.data;
          this.filterListCount = data.count;
          if (this.filterListCount > 0) {
            this.showmodalloader = false;
            this.campaignfilterstatus = false;
            this.createtempaltestatus = true;
            this.campaigncountstatus = false;
          } else {
            this.showmodalloader = false;
            this.campaignfilterstatus = true;
            this.createtempaltestatus = false;
            this.campaigncountstatus = true;
          }
        } else {
          this.filterList = null;
          this.showmodalloader = false;
          this.campaignfilterstatus = false;
          this.createtempaltestatus = false;
        }
      });
    },
    handleInterviewDate() {
      // var current_minute = moment(ev).format('mm');
      // var new_minute = 15;
      // if (current_minute >= 1 && current_minute <= 14) {
      //   new_minute = 15;
      // } else if (current_minute >= 15 && current_minute <= 29) {
      //   new_minute = 30;
      // } else if (current_minute >= 30 && current_minute <= 44) {
      //   new_minute = 45;
      // } else if (current_minute >= 45 && current_minute <= 59) {
      //   new_minute = 0;
      // }
      var hours = new Date(moment().add(4, 'hours')._d);
      this.scheduletime = new Date(moment(hours).minute(new Date().getMinutes()+5)._d);

      //if (moment(this.scheduledate).format('YYYY-MM-DD') == moment(this.todaysDate).format('YYYY-MM-DD') 
      //&& moment.utc(this.scheduletime).format('HH:mm') <= moment.utc(this.todaysDate).format('HH:mm')) {
    },
    CreateNewCampaignBtnbtn() {
      this.errormsg = '';
      if (moment(this.scheduledate).format('YYYY-MM-DD') == moment(this.todaysDate).format('YYYY-MM-DD') &&
        moment.utc(this.scheduletime).format('HH:mm') <= moment.utc(this.todaysDate).format('HH:mm')) {
        this.errormsg = 'Please select a time, four hours from now.';
      } else {
        this.v$.$validate();
        let fields = {};
        fields["co2"] = this.campaignname;
        fields["co3"] = this.campaigntype;
        fields["co4"] = this.templatename;
        fields["co5"] = moment(this.scheduledate).format("YYYY-MM-DD") + ' ' + moment(this.scheduletime).format('HH:mm:ss');
        fields["co6"] = this.filterList;
        fields["co7"] = this.filterListCount;
        console.log(fields);
        this.v$.$validate();
        if (!this.v$.$error) {
          this.createloader = true;
          this.ApiService.addScheduleCampaign(fields).then((items) => {
            if (items.success == true) {
              this.createloader = false;
              var successMsg = items.message;
              this.$toast.open({
                message: successMsg,
                type: "success",
                duration: 3000,
                position: "top-right",
              });
              this.newcampaignmodalstatus = false;
              this.showmodalloader = false;
              this.campaignfilterstatus = false;
              this.createtempaltestatus = false;
              this.getcampaign();
              setTimeout(() => {
                this.v$.$reset();
              }, 0);
              this.balancealert1 = '';
              this.balancealert2 = '';
              this.balancealert3 = '';
              this.balancealertstatus = false;
            } else {
              this.balancealert1 = items.balance_message1;
              this.balancealert2 = items.balance_message2;
              this.balancealert3 = items.balance_message3;
              this.createloader = false;
              this.balancealertstatus = true;
            }
          });
        }
      }
    },
    campaignLogsRedirectPage(data) {
      let routeData = router.resolve({
        name: "CampaignLogs",
        params: { campaignId: data.co1 },
      });
      window.open(routeData.href, "_blank");
      window.open(routeData.href, "_blank");
    },
    campaignReportRedirectPage(data) {
      let routeData = router.resolve({
        name: "VoterCampaignReports",
        params: { campaignId: data.co1 },
      });
      window.open(routeData.href, "_blank");
    },
    getareas(e) {
      this.ApiService.getareas(e).then((data) => {
        if (data.status == 200) {
          this.filterareaList = data.data;
        } else {
          this.filterareaList = null;
        }
      });
    },
    getbloodgroups(e) {
      this.ApiService.getbloodgroups(e).then((data) => {
        if (data.status == 200) {
          this.bloodgroupList = data.data;
        } else {
          this.bloodgroupList = null;
        }
      });
    },

    validatePinCodeNumber(e) {
      this.errStatus = false;
      if (!this.validPinCode(e)) {
        this.pincodeerr = "Please enter valid pincode";
        this.errStatus = true;
      }
      if (this.errStatus == false) {
        this.pincodeerr = "";
        return true;
      }
    },
    validPinCode: function (e) {
      var re = /^((?!(0))[0-9]{6,6})$/;
      return re.test(e);
    },
    validateAgeRangeNumber(e) {
      this.errStatus = false;
      if (!this.validAgeRange(e)) {
        this.agerangeeerr = "Please enter minimum 1 digit";
        this.errStatus = true;
      }
      if (this.agerange == 0) {
        this.agerangeeerr = "Please enter valid age";
        this.errStatus = true;
      }
      if (this.errStatus == false) {
        this.agerangeeerr = "";
        return true;
      }
    },
    validAgeRange: function (e) {
      var re = /([0-9]){1,2}$/;
      return re.test(e);
    },
    validateAgeRangeFromNumber(e) {
      this.errStatus = false;
      if (!this.validAgeFromRange(e)) {
        this.agerangefromeerr = "Please enter minimum 1 digit";
        this.errStatus = true;
      }
      if (this.agerangefrom == 0) {
        this.agerangefromeerr = "Please enter valid age";
        this.errStatus = true;
      }
      if (this.errStatus == false) {
        this.agerangefromeerr = "";
        return true;
      }
    },
    validAgeFromRange: function (e) {
      var re = /([0-9]){1,2}$/;
      return re.test(e);
    },
    validateAgeRangeToNumber(e) {
      this.errStatus = false;
      if (!this.validAgeToRange(e)) {
        this.agerangetoeerr = "Please enter minimum 1 digit";
        this.errStatus = true;
      }
      if (parseInt(this.agerangeto) <= parseInt(this.agerangefrom)) {
        this.agerangetoeerr = "Please enter valid age range";
        this.errStatus = true;
      }
      if (this.agerangeto == 0) {
        this.agerangetoeerr = "Please enter valid age";
        this.errStatus = true;
      }
      if (this.errStatus == false) {
        this.agerangetoeerr = "";
        return true;
      }

    },
    validAgeToRange: function (e) {
      var re = /([0-9]){1,2}$/;
      return re.test(e);
    },
    onlysingleagechange($event) {
      this.agerangefrom = '';
      this.agerangeto = '';
      this.agerangefromeerr = '';
      this.agerangetoeerr = '';
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault();
      }
    },
    agerangechange($event) {
      this.agerange = '';
      this.agerangeeerr = '';
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault();
      }
    },
    getcampaigntemplates(e) {
      this.templateloaderflag = true;
      this.ApiService.getcampaigntemplatesList({ campaign_type: e }).then((data) => {
        if (data.status == 200) {
          this.getcampaigntemplateList = data.data;
          this.templateloaderflag = false;
        } else {
          this.getcampaigntemplateList = [];
          this.templateloaderflag = false;
        }
      });
    },
    format_timestamp(value) {
      if (value) {
        var localTime = new Date(value * 1000).toUTCString();
        return moment.utc(localTime).format("DD/MM/YYYY @ hh:mm A");
      } else {
        return '-';
      }
    },
    clearfilterinput(e) {
      this.ak5 = '';
      this.ak24 = '';
      this.ak62 = null;
      this.ak60 = null;
      this.ak20 = null;
      this.ak18 = null;
      this.ak22 = null;
      this.agerange = "";
      this.agerangefrom = '';
      this.agerangeto = '';
      this.pincodevalue = "";
      this.ak44 = null;
      this.agerangeeerr = '';
      this.pincodeerr = '';
      this.agerangefromeerr = '';
      this.agerangetoeerr = '';
      if (e == true) {
        this.hideotherfilter = true;
      } else if (e == false) {
        this.hideotherfilter = false;
      }
    },
    onlypincodeAddFormat($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault();
      }
      var addpincodeInput = document.getElementById("addpincodeInput");
      if ((addpincodeInput.value == '' || addpincodeInput.length > 1) && (keyCode == 48)) {
        $event.preventDefault();
      }
    },
    isLetterWithSpace(e) {
      let charspace = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z\s]+$/.test(charspace)) return true;
      else e.preventDefault();
    },
    onlyNumberMobile($event) {
        let keyCode = $event.keyCode ? $event.keyCode : $event.which;
        if (keyCode < 48 || keyCode > 57) {
            $event.preventDefault();
        }

        var mobileInput = document.getElementById("formMobileNoInput");
        if ((mobileInput.value == '' || mobileInput.length > 1) && (keyCode == 48 || keyCode == 49 || keyCode == 50 || keyCode == 51 || keyCode == 52)) {
            $event.preventDefault();
        }
    },
  },
};
</script>
<style>
.modal-body-fixed-height {
  min-height: 435px;
}

.voter-already-user-status {
  padding: 8px 16px;
  background: #e4f8f0 0% 0% no-repeat padding-box;
  border: 1px solid #bbd9cc;
  margin-bottom: 15px;
  color: #63957d;
  font-weight: 700;
  font-family: "AcuminPro-SemiBold";
}

.balance-alert-status-outer {
  padding: 8px 16px;
  background: #ffcdd2 0% 0% no-repeat padding-box;
  border: 1px solid #d5b1b1;
  margin-bottom: 15px;
  color: #c63737;
  font-weight: 700;
  font-family: "AcuminPro-SemiBold";
}
</style>